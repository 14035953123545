import logo from "./assets/images/logo.png";
import banner from "./assets/images/unplugged.jpeg";
import germanFlag from "./assets/images/german.png";
import frenchFlag from "./assets/images/french.png";
import englishFlag from "./assets/images/english.png";
import "./App.css";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">
      <div id="banner" className="banner">
        <img src={logo} alt="Logo" className="logo" />
        <img src={banner} alt="Unplugged" className="banner-img" />
      </div>
      <main>
        <div className="card-container">
          <div className="card">
            <div className="top-section">
              <img src={germanFlag} alt="Flag" className="img-flag" />
            </div>
            <div className="main-section">
              <h3>Wo bin ich</h3>
              <p>
                Es tut uns leid, aber der von Ihnen angeklickte Link verweist
                auf das ehemalige Intranet mit der Domain «intranet.bfh.ch».
                Dieses ist seit Februar 2023 nicht mehr in Betrieb. Deshalb
                wurden Sie auf diese Seite weitergeleitet.
              </p>
              <h3>Wo gehts hin</h3>
              <p>
                Wenn Sie Mitarbeiter*in sind, können Sie mit einem Klick auf die
                Schaltfläche «myBFH» ins neue Intranet wechseln. Wenn Sie
                Student*in sind, gelangen Sie mit einem Klick auf die
                Schaltfläche «BFH Campus App» dorthin.
              </p>
              <h3>Was ist zu tun</h3>
              <p>
                Wir bitten Sie, den Link, der Sie hierher geführt hat,
                anzupassen oder der zuständigen Person zu melden, damit er
                ersetzt oder bereinigt werden kann. Bei Fragen können Sie sich
                an das <a href="mailto:servicedesk@bfh.ch">BFH Servicedesk</a>{" "}
                wenden. Vielen Dank.
              </p>
            </div>
            <div className="bottom-section">
              <a href="https://mybfh.ch" className="buttonLink">
                <button>myBFH</button>
              </a>
              <a href="https://campusapp.bfh.ch/" className="buttonLink">
                <button>Campus App</button>
              </a>
            </div>
          </div>
          <div className="card">
            <div className="top-section">
              <img src={frenchFlag} alt="Flag" className="img-flag" />
            </div>
            <div className="main-section">
              <h3>Où suis-je</h3>
              <p>
                Nous sommes désolés, mais le lien sur lequel vous avez cliqué
                renvoie à l'ancien intranet dont le domaine est
                "intranet.bfh.ch". Celui-ci n'est plus en service depuis février
                2023. C'est pourquoi vous avez été redirigé vers cette page.
              </p>
              <h3>Où aller</h3>
              <p>
                Si vous êtes collaborateur, vous pouvez accéder au nouvel
                Intranet en cliquant sur le bouton "myBFH". Si vous êtes
                étudiant, vous y accédez en cliquant sur le bouton "BFH Campus
                App".
              </p>
              <h3>Que faut-il faire</h3>
              <p>
                Nous vous prions d'adapter le lien qui vous a conduit ici ou de
                le signaler à la personne compétente afin qu'il puisse être
                remplacé ou corrigé. Si vous avez des questions, vous pouvez
                vous adresser au{" "}
                <a href="mailto:servicedesk@bfh.ch">Servicedesk de la BFH</a>.
                Merci beaucoup.
              </p>
              <p className="hintText">Ce texte a été traduit à la machine.</p>
            </div>
            <div className="bottom-section">
              <a href="https://mybfh.ch" className="buttonLink">
                <button>myBFH</button>
              </a>
              <a href="https://campusapp.bfh.ch/" className="buttonLink">
                <button>Campus App</button>
              </a>
            </div>
          </div>
          <div className="card">
            <div className="top-section">
              <img src={englishFlag} alt="German Flag" className="img-flag" />
            </div>
            <div className="main-section">
              <h3>Where am I</h3>
              <p>
                We are sorry, but the link you clicked on refers to the former
                intranet with the domain "intranet.bfh.ch". This is no longer in
                operation since February 2023. Therefore you have been
                redirected to this page.
              </p>
              <h3>Where to go</h3>
              <p>
                If you are an employee, you can switch to the new intranet by
                clicking on the "myBFH" button. If you are a student, you can
                get there by clicking on the "BFH Campus App" button.
              </p>
              <h3>What to do</h3>
              <p>
                We ask you to adjust the link that led you here or report it to
                the appropriate person so that it can be replaced or cleaned up.
                If you have any questions, you can contact the{" "}
                <a href="mailto:servicedesk@bfh.ch">BFH service desk</a>. Thank
                you very much.
              </p>
            </div>
            <div className="bottom-section">
              <a href="https://mybfh.ch" className="buttonLink">
                <button>myBFH</button>
              </a>
              <a href="https://campusapp.bfh.ch/" className="buttonLink">
                <button>Campus App</button>
              </a>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default App;
