import React from "react";
import { useState, useEffect } from "react";

function Footer() {
  const [showFooter, setShowFooter] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    if (window.innerHeight >= document.body.offsetHeight) {
      setShowFooter(true);
    }
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function handleScroll() {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight ||
      window.innerHeight >= document.body.offsetHeight
    ) {
      setShowFooter(true);
    } else {
      setShowFooter(false);
    }
  }

  return (
    <>
      {showFooter && (
        <div id="footer">
          <p>© 2023 - Landing Page "Altes Intranet" - Version: 1.0.2</p>
        </div>
      )}
    </>
  );
}

export default Footer;
